
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import gdbx from '@/store/modules/gdbx';
import BaseFilterable from './BaseFilterable.vue';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import { addComma, roundToFixed } from '../../../../util/number';
import { AgentSalesMetrics } from '@/store/models.def';

@Component({
	components: { BaseFilterable, DataInfo },
})
export default class AgentSalesMetricTableFilterable extends BaseFilterableContainer {
	@Prop({ default: () => ({}) }) public year1Result!: AgentSalesMetrics;
	@Prop({ default: () => ({}) }) public year2Result!: AgentSalesMetrics;

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	// Year 1

	public get firstYear() {
		return this.year1Result.year;
	}
	public get year1SalesAmount() {
		return addComma(this.year1Result.salesAmount, this.numDecimal);
	}
	public get year1SalesDocQty() {
		return this.year1Result.salesDocQty;
	}
	public get year1AverageSalesPerCase() {
		return addComma(this.year1Result.averageSalesPerCase, this.numDecimal);
	}
	public get year1AverageSalesCase() {
		return roundToFixed(this.year1Result.averageSalesCase, this.numDecimal);
	}
	public get year1AverageSales() {
		return addComma(this.year1Result.averageSales, this.numDecimal);
	}
	public get year1AverageSalesCycle() {
		return roundToFixed(this.year1Result.averageSalesCycle, this.numDecimal);
	}
	public get year1QuotationAmount() {
		return addComma(this.year1Result.quotationAmount, this.numDecimal);
	}
	public get year1QuotationDocQty() {
		return this.year1Result.quotationDocQty;
	}
	public get year1AverageQuotationCase() {
		return roundToFixed(this.year1Result.averageQuotationCase, this.numDecimal);
	}
	public get year1WinRate() {
		return roundToFixed(this.year1Result.winRate, this.numDecimal);
	}

	// Year 2

	public get secondYear() {
		return this.year2Result.year;
	}
	public get year2SalesAmount() {
		return addComma(this.year2Result.salesAmount, this.numDecimal);
	}
	public get year2SalesDocQty() {
		return this.year2Result.salesDocQty;
	}
	public get year2AverageSalesPerCase() {
		return addComma(this.year2Result.averageSalesPerCase, this.numDecimal);
	}
	public get year2AverageSalesCase() {
		return roundToFixed(this.year2Result.averageSalesCase, this.numDecimal);
	}
	public get year2AverageSales() {
		return addComma(this.year2Result.averageSales, this.numDecimal);
	}
	public get year2AverageSalesCycle() {
		return roundToFixed(this.year2Result.averageSalesCycle, this.numDecimal);
	}
	public get year2QuotationAmount() {
		return addComma(this.year2Result.quotationAmount, this.numDecimal);
	}
	public get year2QuotatioDocQty() {
		return this.year2Result.quotationDocQty;
	}
	public get year2AverageQuotationCase() {
		return roundToFixed(this.year2Result.averageQuotationCase, this.numDecimal);
	}
	public get year2WinRate() {
		return roundToFixed(this.year2Result.winRate, this.numDecimal);
	}
}
