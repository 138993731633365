
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import LineChartFilterable from '@/components/reports-v2/components/filterables/LineChartFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DimensionData, PermissionsGroup } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import ShowProfitToggle from '@/components/ShowProfitToggle.vue';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import settingx from '@/store/modules/settingx';
import { addComma } from '@/util/number';

@Component({
	components: {
		LineChartFilterable,
		IncludeTaxToggle,
		ShowProfitToggle,
		TableCollapseToggle,
		IncludeCreditNoteToggle,
	},
})
export default class SalesProfitAndLossLineChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['stockItems', 'agents', 'dateRange'];

	public xAxisData: string[] = [];
	public periodTotalData: any[] = [];
	public detailFields: any[] = [];
	public series: echarts.ECharts[] | any = [];

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get includeTax() {
		return settingx.now.includeTax;
	}

	public get showProfit() {
		return settingx.now.showProfit;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get color() {
		return this.showProfit
			? [GobiColor.DEEP_TEAL, GobiColor.ORANGE, GobiColor.PINK]
			: [GobiColor.DEEP_TEAL, GobiColor.PINK];
	}

	public get groupedBy() {
		const difference = this.selectedDateRange[1] - this.selectedDateRange[0];
		if (difference >= 365 * 3 * 24 * 60 * 60 * 1000) {
			return 'year';
		}

		if (difference >= 62 * 24 * 60 * 60 * 1000) {
			return 'month';
		}

		// if (difference >= 14 * 24 * 60 * 60 * 1000) {
		// 	return 'week';
		// }

		return 'day';
	}

	public get expensiveHook() {
		const {
			color,
			showProfit,
			includeTax,
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
			includeCreditNote,
		} = this;
		return JSON.stringify([
			color,
			showProfit,
			includeTax,
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
			includeCreditNote,
		]);
	}

	public async expensiveCalc() {
		let pa = 0.2;
		let pb = 0;

    const ref = FilteredDatabase.ref('sales')
        .agents(this.selectedAgents)
        .dateRange(this.selectedDateRange)
        .includes('docType', ['IV', 'CS']);

		pb += pa;
		pa = 0.2;

		const difference = this.selectedDateRange[1] - this.selectedDateRange[0];

		const allAmounts: DimensionData[] = await this._loadDimensionByPeriod(
			ref,
			difference >= 365 * 3 * 24 * 60 * 60 * 1000
				? 'year'
				: difference >= 62 * 24 * 60 * 60 * 1000
				? 'month'
				: 'day',
			pa,
			pb,
			this.includeTax ? 'amountIncludeTax' : 'amount',
		);

		pb += pa;
		pa = 0.2;

		const allProfits: DimensionData[] = await this._loadDimensionByPeriod(
			ref,
			difference >= 365 * 3 * 24 * 60 * 60 * 1000
				? 'year'
				: difference >= 62 * 24 * 60 * 60 * 1000
				? 'month'
				: 'day',
			pa,
			pb,
			this.includeTax ? 'profitIncludeTax' : 'profit',
		);

		pb += pa;
		pa = 0.2;

		const allCreditNotes: DimensionData[] = await this._loadDimensionByPeriod(
			FilteredDatabase.ref('creditNotes')
				.agents(this.selectedAgents)
				.dateRange(this.selectedDateRange)
				.stockItems(this.selectedStockItems),
			difference >= 365 * 3 * 24 * 60 * 60 * 1000
				? 'year'
				: difference >= 62 * 24 * 60 * 60 * 1000
				? 'month'
				: 'day',
			pa,
			pb,
			this.includeTax ? 'amountIncludeTax' : 'amount',
		);

		this.xAxisData = allAmounts.map((dd) => dd.text);

		pb += pa;
		pa = 0.2;

		const totalSales = allAmounts.reduce((a, b) => a + b.sum, 0);

		const totalProfit = allProfits
			.map((dd) => {
				return dd.sum;
			})
			.reduce((a, b) => a + b, 0);

		const totalCreditNotes = allCreditNotes.reduce((a, b) => a + b.sum, 0);

		this.detailFields = [
			{
				key: 'total_sales',
			},
			{
				key: 'total_sales_profit',
			},
		];

		this.periodTotalData = [
			{
				total_sales:
					this.currencySymbol +
					' ' +
					addComma(totalSales, this.numDecimal, true),
				total_sales_profit:
					this.currencySymbol +
					' ' +
					addComma(totalProfit, this.numDecimal, true),
				total_credit_notes:
					this.currencySymbol +
					' ' +
					addComma(totalCreditNotes, this.numDecimal, true),
			},
		];

		if (this.includeCreditNote) {
			this.detailFields.push({
				key: 'total_credit_notes',
			});
		}

		const sales = {
			name: 'Sales',
			data: allAmounts.map((item) => item.sum),
			type: 'line',
			areaStyle: {
				color: GobiColor.COLOR1,
				opacity: 1,
			},
			smooth: true,
		};

		const profit = {
			name: 'Profit / Loss',
			data: allProfits.map((item) => item.sum),
			type: 'line',
			areaStyle: {
				color: GobiColor.COLOR2,
				opacity: 1,
			},
			smooth: true,
		};

		const creditNotes = {
			name: 'Credit Notes',
			data: allCreditNotes.map((item) => item.sum),
			type: 'line',
			color: GobiColor.COLOR3,
			smooth: true,
		};

		this.series = [sales];

		if (this.showProfit) {
			this.series.push(profit);
		}

		if (this.includeCreditNote) {
			this.series.push(creditNotes);
		}

		this.saveHistory('series', 'xAxisData', 'periodTotalData', 'detailFields');
	}
}
