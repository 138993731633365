
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { DimensionData, PermissionsGroup } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import * as echarts from 'echarts';
import { GobiColor } from '@/helpers/color';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import settingx from '@/store/modules/settingx';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';

@Component({
	components: {
		StackedBarChartFilterable,
		IncludeTaxToggle,
	},
})
export default class AgentSalesBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales'];
	}

	public filterIds: Array<
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange', 'stockItems', 'agents'];

	public xAxisData: string[] = [];
	public series: echarts.ECharts[] | any = [];

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get includeTax() {
		return settingx.now.includeTax;
	}

	public get expensiveHook() {
		const {
			includeTax,
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		} = this;
		return JSON.stringify([
			includeTax,
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		let pa = 0.4;
		let pb = 0;

		const ref = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.stockItems(this.selectedStockItems);

		let sortedMap: DimensionData[] = await this._loadDimensionByFilters(
			ref,
			'stockGroup',
			pa,
			pb,
		);

		sortedMap = FilterWidget.limitSlices(sortedMap, 6);

		pb += pa;
		pa = 0.6;

		let results: DimensionData[] = await this._loadDimensionByFilters(
			ref,
			'agents',
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(
					reff,
					sortedMap,
					paa,
					pbb,
					this.includeTax ? 'amountIncludeTax' : 'amount',
				),
		);

		results = FilterWidget.limitSlices(results).filter((dd) => dd.sum !== 0);

		const agentList = results.map((dd) => dd.text);
		const agentTotalSalesList = results.map((dd) => dd.sum);

		this.xAxisData = agentList;

		this.series = sortedMap.map((sg, index) => ({
			name: sg.text,
			data: results.map((dd) => dd.subDimension![index].sum),
			type: 'bar',
			barWidth: '40%',
			stack: 'a',
		}));

		this.series.push({
			name: 'Total',
			type: 'bar',
			barWidth: '10%',
			color: GobiColor.COLOR7,
			data: agentTotalSalesList,
		});

		this.saveHistory(
			'series',
			'xAxisData',
			'periodTotalData',
			'periodTotalFields',
		);
	}
}
