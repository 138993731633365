
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import LineChartFilterable from '@/components/reports-v2/components/filterables/LineChartFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DimensionData, PermissionsGroup } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import ShowProfitToggle from '@/components/ShowProfitToggle.vue';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';

@Component({
	components: {
		LineChartFilterable,
		IncludeTaxToggle,
		ShowProfitToggle,
		TableCollapseToggle,
	},
})
export default class SalesOrderTrendLineChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange'];

	public xAxisData: string[] = [];
	public periodTotalData: any[] = [];
	public series: echarts.ECharts[] | any = [];

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get color() {
		return [GobiColor.COLOR1];
	}

	public get groupedBy() {
		const difference = this.selectedDateRange[1] - this.selectedDateRange[0];
		if (difference >= 365 * 3 * 24 * 60 * 60 * 1000) {
			return 'year';
		}

		if (difference >= 62 * 24 * 60 * 60 * 1000) {
			return 'month';
		}

		return 'day';
	}

	public get expensiveHook() {
		const { selectedDateRange, selectedAgents, selectedStockItems } = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		const ref = FilteredDatabase.ref('salesOrder')
			.stockItems(this.selectedStockItems)
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange);

		const cards = await (await ref.get()).getCards();

		const difference = this.selectedDateRange[1] - this.selectedDateRange[0];

		const allAmounts: DimensionData[] = await this._loadDimensionByPeriod(
			ref,
			difference >= 365 * 3 * 24 * 60 * 60 * 1000
				? 'year'
				: difference >= 62 * 24 * 60 * 60 * 1000
				? 'month'
				: 'day',
			1,
			0,
			'amount',
		);

		// pb += pa;
		// pa = 0.5;

		// const allCreditNotes: DimensionData[] = await this._loadDimensionByPeriod(
		// 	FilteredDatabase.ref('creditNotes')
		// 		.agents(this.selectedAgents)
		// 		.dateRange(this.selectedDateRange)
		// 		.stockItems(this.selectedStockItems),
		// 	difference >= 365 * 3 * 24 * 60 * 60 * 1000
		// 		? 'year'
		// 		: difference >= 62 * 24 * 60 * 60 * 1000
		// 		? 'month'
		// 		: 'day',
		// 	pa,
		// 	pb,
		// 	'amount',
		// );

		this.xAxisData = allAmounts.map((dd) => dd.text);

		const sales = {
			name: 'Sales',
			data: allAmounts.map((item) => item.sum),
			type: 'line',
			areaStyle: {
				color: GobiColor.COLOR1,
				opacity: 1,
			},
			smooth: true,
		};

		// const creditNotes = {
		// 	name: 'Credit Notes',
		// 	data: allCreditNotes.map((item) => item.sum),
		// 	type: 'line',
		// 	color: GobiColor.COLOR3,
		// 	smooth: true,
		// };

		this.series = [sales];

		this.saveHistory('series', 'xAxisData', 'periodTotalData');
	}
}
