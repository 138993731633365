
import {Component, Watch} from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import {PermissionsGroup, ValueText, DimensionMap} from '@/store/models.def';
import {TableItemFormatter} from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import CustomerListFilter from '@/components/reports-v2/components/filters/CustomerListFilter.vue';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';
import MultiSelect from 'primevue/multiselect';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';

@Component({
	components: {
		TableFilterable,
		IncludeCreditNoteToggle,
		IncludeDebitNoteToggle,
		CustomerListFilter,
		MultiSelect,
	},
})
export default class StockItemSalesByCustomerTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['stocks', 'sales'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange', 'stockItems', 'customers'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];
	public selectedCustomersList: string[] = [];

	@Watch('options', { immediate: true })
	public onOptionsChanged(newVal: ValueText[]) {
		if (this.selectedCustomersList.length === 0) {
			this.selectedCustomersList = newVal.map((item) => item.value);
		}
	}

	public get options() {
		return gdbx.allCustomers;
	}

	public get exportFileName() {
		const formattedDateRange = [
			moment(this.selectedDateRange[0]).format('DD MMM YY'),
			moment(this.selectedDateRange[1]).format('DD MMM YY'),
		];
		return (
			'Stock Item Sales By Customer' +
			'_' +
			formattedDateRange[0] +
			' to ' +
			formattedDateRange[1]
		);
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

	public get expensiveHook() {
		const {
			selectedCustomersList,
			includeCreditNote,
      includeDebitNote,
			selectedDateRange,
			selectedCustomers,
			selectedStockItems,
		} = this;
		return JSON.stringify([
			selectedCustomersList,
			includeCreditNote,
      includeDebitNote,
			selectedDateRange,
			selectedCustomers,
			selectedStockItems,
		]);
	}

    public async expensiveCalc() {
        const salesRef = FilteredDatabase.ref('sales')
            .dateRange(this.selectedDateRange)
            .customers(this.selectedCustomersList)
            .stockItems(this.selectedStockItems)
            .includes('docType', this.includeDebitNote ? ['IV', 'CS', 'DN'] : ['IV', 'CS']);

        const creditNoteRef = FilteredDatabase.ref('creditNotes')
            .dateRange(this.selectedDateRange)
            .customers(this.selectedCustomersList)
            .stockItems(this.selectedStockItems);

        const stockRef = FilteredDatabase.ref('globalStocks')
            .stockItems(this.selectedStockItems);

        const salesCards = await (await salesRef.get()).getCards();

        const salesStock = [...new Set(salesCards.map((item) => item.itemCode))];

        const stockCards = await (await stockRef.get()).getCards();

        const stockMap: DimensionMap[] = salesStock.map((code, index) => ({
            filterType: 'string',
            filterKey: 'itemCode',
            value: [code],
            text: (stockCards.find((a) => {
                return a.itemCode === code;
            }) ? stockCards.find((a) => {
                return a.itemCode === code;
            }).itemDesc : '----'),
        }));

        const pa = 0.20;
        let pb = 0;

        const salesAmountDD = await this._loadDimension(
            salesRef,
            stockMap,
            pa,
            pb,
            'amount',
        );

        pb += pa;

        const salesQuantityDD = await this._loadDimension(
            salesRef,
            stockMap,
            pa,
            pb,
            'quantity',
        );

        pb += pa;

        const profitAmountDD = await this._loadDimension(
            salesRef,
            stockMap,
            pa,
            pb,
            'profit',
        );

        pb += pa;

        const creditNotesAmountDD = await this._loadDimension(
            creditNoteRef,
            stockMap,
            pa,
            pb,
            'amount',
        );

        pb += pa;

        const creditNotesQuantityDD = await this._loadDimension(
            creditNoteRef,
            stockMap,
            pa,
            pb,
            'quantity',
        );
        this.generateLoadingText(1);

        // Assuming tableFields setup remains unchanged
        this.tableFields = [
            { key: 'item_code', sortable: true },
            { key: 'item_description', sortable: true },
            { key: 'amount', sortable: true, formatter: TableItemFormatter.currency },
            { key: 'profit', sortable: true, formatter: TableItemFormatter.currency },
            { key: 'profit_margin', sortable: true, formatter: TableItemFormatter.percentage },
            { key: 'quantity', sortable: true, formatter: TableItemFormatter.basic },
            { key: 'price', sortable: true, formatter: TableItemFormatter.basic },
        ];

        this.tableItems = salesAmountDD.map((dd, index) => {
            const creditIndex = creditNotesQuantityDD.findIndex((a) => a.text === dd.text);
            const creditAmount = creditNotesAmountDD[creditIndex] ? creditNotesAmountDD[creditIndex].sum : 0;
            const creditQuantity = creditNotesQuantityDD[creditIndex] ? creditNotesQuantityDD[creditIndex].sum : 0;

            const itemQuantity = this.includeCreditNote ?
                salesQuantityDD[index].sum - creditQuantity :
                salesQuantityDD[index].sum;
            const itemAmount = this.includeCreditNote ? dd.sum - creditAmount : dd.sum;

            if (itemQuantity) {
                return {
                    item_code: dd.value[0],
                    item_description: dd.text,
                    amount: itemAmount,
                    profit: profitAmountDD[index].sum,
                    profit_margin: dd.sum !== 0 ? (profitAmountDD[index].sum / dd.sum) * 100 : 0,
                    quantity: itemQuantity,
                    price: itemQuantity !== 0 ? Math.ceil(itemAmount / itemQuantity) : 0,
                };
            }
        });
        this.saveHistory('tableItems', 'tableFields');
    }

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
