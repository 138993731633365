
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import SecondaryLineBarChart from '@/components/reports-v2/components/elements/charts/SecondaryLineBarChart.vue';
import moment, { Moment } from 'moment';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import { TooltipFormatter } from '../elements/charts/helpers/tooltipFormatter';
import { LabelFormatter } from '../elements/charts/helpers/labelFormatter';
import { addComma } from '@/util/number';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import BaseFilterable from './BaseFilterable.vue';
import { GobiColor } from '../../../../helpers/color';
import { DataViewFormatter } from '../elements/charts/helpers/dataViewFormatter';
import { DataZoomList } from '../elements/charts/helpers/dataZoom';

@Component({
	components: {
		SecondaryLineBarChart,
		DataInfo,
		BaseFilterable,
	},
})
export default class SecondaryLineBarChartFilterable extends BaseFilterableContainer {
	// x-Axis
	@Prop({ default: () => [] }) public readonly xAxisData!: any[];
	@Prop({ default: '' }) public readonly xAxisLabel!: string;
	@Prop({ default: 'category' }) public readonly xAxisType!: any;
	@Prop({ default: 15 }) public readonly xAxisRotate!: number;
	// y-Axis
	@Prop({ default: '' }) public readonly yAxisLabel!: string;
	@Prop({ default: '' }) public readonly yAxisLabel2!: string;
	@Prop({ default: 'value' }) public readonly yAxisType!: any;
	@Prop({ default: 0 }) public readonly yAxis2Min!: number;
	@Prop({}) public readonly yAxis2Max!: number;
	// Formatter
	@Prop({ default: () => TooltipFormatter.basic })
	public readonly formatter!: any;
	@Prop({ default: () => DataViewFormatter.basic })
	public readonly dataViewFormatter!: any;
	// Other
	@Prop({ default: () => DataZoomList.xAxis('slider', 5, 0) })
	public readonly dataZoom!: echarts.DatasetComponentOption;
	@Prop({})
	public readonly legends!: echarts.LegendComponentOption;
	@Prop({ default: false }) public readonly isHorizontal!: boolean;
	@Prop({
		default: () => [
			GobiColor.COLOR1,
			GobiColor.COLOR2,
			GobiColor.COLOR3,
			GobiColor.COLOR4,
			GobiColor.COLOR5,
			GobiColor.COLOR6,
		],
	})
	public readonly color!: string[];
	@Prop({ default: () => [] })
	public readonly series!: echarts.ECharts[];

	public get printedTitle() {
		return `${this.title} - ${this.dateFormatted}`;
	}
}
