
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts/core';
import {
	TitleComponent,
	TitleComponentOption,
	ToolboxComponent,
	ToolboxComponentOption,
	TooltipComponent,
	TooltipComponentOption,
	GridComponent,
	GridComponentOption,
	LegendComponent,
	LegendComponentOption,
	DataZoomSliderComponent,
	DataZoomComponentOption,
} from 'echarts/components';
import {
	LineChart,
	LineSeriesOption,
	BarChart,
	BarSeriesOption,
} from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { GobiColor } from '@/helpers/color';
import resize from 'vue-resize-directive';
import uuidv1 from 'uuid/v1';
import { DataZoomList } from '@/components/reports-v2/components/elements/charts/helpers/dataZoom';
import { TooltipFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tooltipFormatter';
import { DataViewFormatter } from './helpers/dataViewFormatter';

echarts.use([
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	GridComponent,
	LegendComponent,
	LineChart,
	CanvasRenderer,
	UniversalTransition,
	DataZoomSliderComponent,
	BarChart,
]);

type EChartsOption = echarts.ComposeOption<
	| TitleComponentOption
	| ToolboxComponentOption
	| TooltipComponentOption
	| GridComponentOption
	| LegendComponentOption
	| LineSeriesOption
	| DataZoomComponentOption
	| BarSeriesOption
>;

@Component({
	directives: {
		resize,
	},
})
export default class SecondaryLineBarChart extends Vue {
	// y-Axis
	@Prop({ default: '' }) public readonly yAxisLabel!: string;
	// y-Axis 2
	@Prop({ default: '' }) public readonly yAxisLabel2!: string;
	@Prop({ default: 0 }) public readonly yAxis2Min!: number;
	@Prop({}) public readonly yAxis2Max!: number;
	// x-Axis
	@Prop({ default: () => [] }) public readonly xAxisData!: string[];
	@Prop({ default: '' }) public readonly xAxisLabel!: string;
	@Prop({ default: '' }) public readonly xAxisType!: any;
	@Prop({ default: 15 }) public readonly xAxisRotate!: number;
	// Formatter
	@Prop({ default: () => TooltipFormatter.basic })
	public readonly formatter!: any;
	@Prop({ default: () => DataViewFormatter.basic })
	public readonly dataViewFormatter!: any;
	// Other
	@Prop({
		default: () => [
			GobiColor.COLOR1,
			GobiColor.COLOR2,
			GobiColor.COLOR3,
			GobiColor.COLOR4,
			GobiColor.COLOR5,
			GobiColor.COLOR6,
		],
	})
	public readonly color!: string[];
	@Prop({ default: () => DataZoomList.xAxis('slider', 5, 0) })
	public readonly dataZoom!: EChartsOption;
	@Prop({}) public readonly legends!: EChartsOption;
	@Prop({ default: 'Title' }) public readonly title!: string;
	@Prop({ default: false }) public readonly isHorizontal!: boolean;
	@Prop({ default: () => [] })
	public readonly series!: EChartsOption[];

	public mainChart: echarts.ECharts | null = null;
	public elId: string = '';

	public created() {
		this.elId = uuidv1();
	}

	public mounted() {
		this.initChart();
	}

	public initChart() {
		const myChart: any = document.getElementById(this.elId);

		const mainChart = (this.mainChart = echarts.init(myChart));
		this.updateChart();
	}

	@Watch('xAxisData')
	public onXAxisDataChange(newVal, oldVal) {
		this.updateChart();
	}

	@Watch('series')
	public onSeriesChange(newVal, oldVal) {
		this.updateChart();
	}

	public updateChart() {
		if (!this.mainChart) {
			return;
		}
		const option: echarts.EChartsCoreOption = {
			color: this.color,
			tooltip: {
				confine: true,
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
				formatter: this.formatter,
			},
			legend: {
				type: 'scroll',
				selectedMode: true,
				data: this.legends,
				padding: [0, 180, 0, 0],
			},
			toolbox: {
				feature: {
					saveAsImage: {
						name: this.title,
						title: 'Save as image',
						iconStyle: {
							borderColor: GobiColor.COLOR1,
						},
					},
					dataView: {
						show: true,
						readOnly: true,
						title: 'Chart data',
						lang: ['', 'Close', ''],
						iconStyle: {
							borderColor: GobiColor.COLOR1,
						},
						optionToContent: this.dataViewFormatter,
					},
				},
			},
			grid: {
				top: this.legends && this.legends.length === 0 ? '10%' : '20%',
				bottom:
					this.dataZoom && this.dataZoom.type === 'slider' ? '20%' : '10%',
				left: `${3 + (this.isHorizontal && this.yAxisLabel ? 5 : 0)}%`,
				right: `${5 + (this.isHorizontal && this.yAxisLabel ? 5 : 0)}%`,
				containLabel: true,
			},
			dataZoom: this.dataZoom ? [this.dataZoom] : undefined,
			xAxis: {
				name: this.xAxisLabel,
				nameTextStyle: {
					fontWeight: 'bold',
				},
				type: this.xAxisType,
				boundaryGap: true,
				data: this.xAxisData,
				axisLabel: {
					rotate: this.xAxisRotate,
					fontSize: 9,
				},
			},
			yAxis: [
				{
					name: this.yAxisLabel,
					nameTextStyle: {
						fontWeight: 'bold',
					},
					min: 0,
				},
				{
					name: this.yAxisLabel2,
					nameTextStyle: {
						fontWeight: 'bold',
					},
					// min: this.yAxis2Min,
					// max: this.yAxis2Max,
					splitLine: {
						show: false,
					},
				},
			],
			series: this.series,
		};
		if (this.isHorizontal) {
			const xAxis = option.xAxis as any;
			option.xAxis = option.yAxis as any;
			option.yAxis = xAxis;
		}
		this.mainChart.setOption(option, true);
	}

	public onResize() {
		if (this.mainChart) {
			this.mainChart.resize();
		}
	}
}
