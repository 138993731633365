
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import BaseFilterable from './BaseFilterable.vue';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import CaseDisplayer from '@/components/reports-v2/components/elements/displayers/CaseDisplayer.vue';

@Component({
	components: {
		CaseDisplayer,
		BaseFilterable,
		DataInfo,
	},
})
export default class CaseDisplayerFilterable extends BaseFilterableContainer {
	@Prop({ default: 0 }) public readonly caseAmount?: string;
	@Prop({ default: 0 }) public readonly totalAmount?: string;
	@Prop({ default: 0 }) public readonly footer!: string;
}
