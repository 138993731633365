
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CaseDisplayerFilterable from '@/components/reports-v2/components/filterables/CaseDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';

@Component({
	components: {
		CaseDisplayerFilterable,
	},
})
export default class TotalQuotationCaseDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['stockItems', 'agents', 'dateRange'];

	public caseAmount = 0;
	public totalAmount = 0;

	public get footer() {
		const date1 = moment(this.selectedDateRange[0]).format('DD MMM YYYY');
		const date2 = moment(this.selectedDateRange[1]).format('DD MMM YYYY');
		return date1 + ' - ' + date2;
	}

	public get expensiveHook() {
		const { selectedDateRange, selectedAgents, selectedStockItems } = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		let pa = 0.3;
		let pb = 0;

		const ref = FilteredDatabase.ref('quotations')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.stockItems(this.selectedStockItems);

		pb += pa;
		pa = 0.7;

		const refHead = await ref.get();

		const amount = await refHead.getSum('amount');

		const quantity = (await refHead.getSet('docNo')).size;

		this.totalAmount = amount;
		this.caseAmount = quantity;

		this.saveHistory('caseAmount', 'totalAmount');
	}
}
