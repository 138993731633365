
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	DimensionData,
	PermissionsGroup,
	QuotationDetailCard,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import * as echarts from 'echarts';
import { GobiColor } from '@/helpers/color';

@Component({
	components: {
		StackedBarChartFilterable,
	},
})
export default class SalesConversionRateBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales'];
	}

	public filterIds: Array<
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange', 'stockItems', 'agents'];

	public xAxisData: string[] = [];
	public finalDatas: Array<{
		name: string;
		openAmount: number;
		convertedAmount: number;
		totalAmount: number;
		conversionRate: number;
	}> = [];
	public legends: string[] = ['Converted Value', 'Open Quotation', 'Total'];
	public series: echarts.ECharts[] | any = [];

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get expensiveHook() {
		const { selectedDateRange, selectedAgents, selectedStockItems } = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		const ref = FilteredDatabase.ref('quotations')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.stockItems(this.selectedStockItems);

		const refHead = await ref.get();

		const cards: QuotationDetailCard[] = await refHead.getCards();

		let pa = 0.3;
		let pb = 0;

		const totalDatas: DimensionData[] = await this._loadDimensionByFilters(
			ref,
			'agents',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.3;

		const outstandingDatas: DimensionData[] = await this._loadDimensionByFilters(
			ref,
			'agents',
			pa,
			pb,
			'outstandingAmount',
		);

		pb += pa;
		pa = 0.3;

		const convertedDatas: DimensionData[] = await this._loadDimensionByFilters(
			ref,
			'agents',
			pa,
			pb,
			'convertedQuotation',
		);

		this.finalDatas = convertedDatas
			.map((dd, index) => {
				const object = {
					name: '',
					openAmount: 0,
					convertedAmount: 0,
					totalAmount: 0,
					conversionRate: 0,
				};

				const openAmountIndex = outstandingDatas
					.map((openDD) => openDD.text)
					.indexOf(dd.text);
				const totalAmountIndex = totalDatas
					.map((totalDD) => totalDD.text)
					.indexOf(dd.text);

				const totalCards = [
					...new Set(
						cards
							.filter((item) => item.agent === dd.value[0])
							.map((item) => item.docNo),
					),
				].length;

				const convertedCards = [
					...new Set(
						cards
							.filter(
								(item) =>
									item.agent === dd.value[0] &&
									item.amount !== item.outstandingAmount,
							)
							.map((item) => item.docNo),
					),
				].length;

				object.name = dd.text;
				object.openAmount = outstandingDatas[openAmountIndex].sum;
				object.totalAmount = totalDatas[totalAmountIndex].sum;
				object.convertedAmount = dd.sum;
				object.conversionRate =
					totalCards !== 0 ? (convertedCards / totalCards) * 100 : 0;

				return object;
			})
			.filter((item) => item.totalAmount > 0);

		this.xAxisData = this.finalDatas
			.sort((a, b) => b.conversionRate - a.conversionRate)
			.map((item) => item.name);

		const outstanding = {
			name: 'Open Quotation',
			type: 'bar',
			barWidth: '40%',
			color: GobiColor.COLOR1,
			stack: 'a',
			data: this.finalDatas.map((dd) => dd.openAmount),
		};

		const converted = {
			name: 'Converted Value',
			type: 'bar',
			barWidth: '40%',
			color: GobiColor.COLOR2,
			stack: 'a',
			data: this.finalDatas.map((dd) => dd.convertedAmount),
		};

		const conversionRates = {
			name: 'Conversion Rate (%)',
			type: 'bar',
			barWidth: '0%',
			color: GobiColor.COLOR3,
			itemStyle: {
				opacity: 0,
				color: false,
			},
			data: this.finalDatas.map((item) => item.conversionRate),
		};

		const total = {
			name: 'Total',
			type: 'bar',
			barWidth: '10%',
			color: GobiColor.COLOR4,
			data: this.finalDatas.map((dd) => dd.totalAmount),
		};

		this.generateLoadingText(1);

		this.series = [converted, outstanding, total, conversionRates];

		this.saveHistory('series', 'xAxisData', 'finalDatas');
	}
}
