
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DataInfo from '../DataInfo.vue';
import gdbx from '@/store/modules/gdbx';
import BaseReport from '@/components/reports-v2/BaseReport.vue';

@Component({
	components: { DataInfo },
})
export default class CaseDisplayer extends Vue {
	@Prop({ default: false }) public readonly unfocused!: boolean;
	@Prop({ default: 0 }) public readonly caseAmount!: number;
	@Prop({ default: 0 }) public readonly totalAmount!: number;
	@Prop({ default: '' }) public readonly footer!: string;

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get totalAmountText() {
		return BaseReport.shortenNumber(Math.ceil(this.totalAmount)).toLocaleString();
	}
}
