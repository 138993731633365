
import Component from 'vue-class-component';
import SecondaryLineBarChartFilterable from '@/components/reports-v2/components/filterables/SecondaryLineBarChartFilterable.vue';
import {PermissionsGroup} from '@/store/models.def';
import echarts from 'echarts';
import gdbx from '@/store/modules/gdbx';
import {DataViewFormatter} from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import moment from 'moment/moment';
import {GobiColor} from '@/helpers/color';
import { nestedGroupBy } from '@/util/nestedGroupBy';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
  components: {IncludeDebitNoteToggle, IncludeCreditNoteToggle, SecondaryLineBarChartFilterable},
})
export default class TotalSalesByMonthBarChart extends FilterWidget {
  public get permissionIds(): PermissionsGroup[] {
    return ['sales', 'customers'];
  }

  public dataZoom = [
    {
      type: 'slider',
    },
    {
      type: 'inside',
    },
  ];

  public filterIds: Array<
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
      | 'compareDate1'
      | 'compareDate2'
      | 'comparisonPeriod'
  > = [
    'customers',
    'agents',
    'compareDate1',
    'compareDate2',
    'comparisonPeriod',
  ];

  public xAxisData: string[] = [];
  public series: echarts.ECharts[] | any = [];

  public get currencySymbol() {
    return gdbx.currencySymbol;
  }

  public get yAxisLabel2() {
    let label: string = '';

    label = this.currencySymbol;

    return label;
  }

  public get dataViewFormatter() {
    return DataViewFormatter.salesTracker;
  }

  public get includeCreditNote() {
    return settingx.now.includeCreditNote;
  }

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

  public get dataDateRange() {
    return [
      moment(this.selectedDateRange[0]).subtract(1, 'year').valueOf(),
      this.selectedDateRange[1],
    ];
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      includeCreditNote,
      includeDebitNote,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      includeCreditNote,
      includeDebitNote,
    ]);
  }

  public async expensiveCalc() {
    const thisYearRef = FilteredDatabase.ref('sales')
        .includes('docType', ['IV', this.includeDebitNote ? 'DN' : ''])
        .dateRange(this.selectedDateRange);
    const pastYearRef = FilteredDatabase.ref('sales')
        .includes('docType', ['IV', this.includeDebitNote ? 'DN' : ''])
        .dateRange([
          moment(this.selectedDateRange[0]).subtract(1, 'year').valueOf(),
          moment(this.selectedDateRange[1]).subtract(1, 'year').valueOf(),
        ]);

    const thisYearCNRef = FilteredDatabase.ref('creditNotes')
        .dateRange(this.selectedDateRange);
    const pastYearCNRef = FilteredDatabase.ref('creditNotes')
        .dateRange([
          moment(this.selectedDateRange[0]).subtract(1, 'year').valueOf(),
          moment(this.selectedDateRange[1]).subtract(1, 'year').valueOf(),
        ]);

    const areaRef = FilteredDatabase.ref('globalArea');

    const areaCards = await (await areaRef.get()).getCards();

    let pa = 0.25;
    let pb = 0;

    const thisYearCards = await (await thisYearRef.get()).getCards();
    const pastYearCards = await (await pastYearRef.get()).getCards();

    const thisYearCNCards = await (await thisYearCNRef.get()).getCards();
    const pastYearCNCards = await (await pastYearCNRef.get()).getCards();

    pb += pa;
    pa = 0.25;

    const thisYearCardsGrouped = nestedGroupBy(thisYearCards, ['area']);
    const pastYearCardsGrouped = nestedGroupBy(pastYearCards, ['area']);

    pb += pa;
    pa = 0.25;

    const thisYearCNCardsGrouped = nestedGroupBy(thisYearCNCards, ['area']);
    const pastYearCNCardsGrouped = nestedGroupBy(pastYearCNCards, ['area']);

    pb += pa;
    pa = 0.25;

    const processedThisYearCardsGrouped = [{
      text: '',
      sum: 0,
    }];

    const processedPastYearCardsGrouped = [{
      text: '',
      sum: 0,
    }];

    for (const area in thisYearCardsGrouped) {
      if (area) {
        processedThisYearCardsGrouped.push({
          text: areaCards.filter((a ) => a.code === area)[0].description,
          sum: thisYearCardsGrouped[area].reduce((a, b) => a + b.amount, 0),
        });
      }
    }

    for (const area in pastYearCardsGrouped) {
      if (area) {
        processedPastYearCardsGrouped.push({
          text: area,
          sum: pastYearCardsGrouped[area].reduce((a, b) => a + b.amount, 0),
        });
      }
    }

    for (const area in thisYearCardsGrouped) {
      if (thisYearCNCardsGrouped[area] &&
          processedThisYearCardsGrouped.filter((a) => a.text === area)[0] &&
          this.includeCreditNote) {
        processedThisYearCardsGrouped.filter((a) => a.text === area)[0].sum =
            processedThisYearCardsGrouped.filter((a) => a.text === area)[0].sum -
            thisYearCNCardsGrouped[area].reduce((a, b) => a + b.amount, 0);
      }
    }

    for (const area in pastYearCardsGrouped) {
      if (pastYearCNCardsGrouped[area] &&
          processedPastYearCardsGrouped.filter((a) => a.text === area)[0] &&
          this.includeCreditNote) {
        processedPastYearCardsGrouped.filter((a) => a.text === area)[0].sum =
            processedPastYearCardsGrouped.filter((a) => a.text === area)[0].sum -
            pastYearCNCardsGrouped[area].reduce((a, b) => a + b.amount, 0);
      }
    }

    processedThisYearCardsGrouped.shift();
    processedPastYearCardsGrouped.shift();

    this.xAxisData = processedThisYearCardsGrouped.map((dd ) => {
      return dd.text;
    });

    this.series = [
      {
        name: 'This Year',
        type: 'bar',
        smooth: true,
        barWidth: '40%',
        color: GobiColor.COLOR1,
        data: processedThisYearCardsGrouped.map((dd ) => {
          return dd.sum;
        }),
      },
      {
        name: 'Last Year',
        type: 'bar',
        smooth: true,
        barWidth: '40%',
        color: GobiColor.COLOR3,
        data: processedPastYearCardsGrouped.map((dd ) => {
          return dd.sum;
        }),
      },
    ];

  }
}
