
import Component from 'vue-class-component';
import SecondaryLineBarChartFilterable from '@/components/reports-v2/components/filterables/SecondaryLineBarChartFilterable.vue';
import {PermissionsGroup} from '@/store/models.def';
import echarts from 'echarts';
import gdbx from '@/store/modules/gdbx';
import {DataViewFormatter} from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import moment from 'moment/moment';
import {GobiColor} from '@/helpers/color';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
  components: {IncludeDebitNoteToggle, IncludeCreditNoteToggle, SecondaryLineBarChartFilterable},
})
export default class TotalSalesByMonthBarChart extends FilterWidget {
  public get permissionIds(): PermissionsGroup[] {
    return ['sales', 'customers'];
  }

  public dataZoom = [
    {
      type: 'slider',
    },
    {
      type: 'inside',
    },
  ];

  public filterIds: Array<
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
      | 'compareDate1'
      | 'compareDate2'
      | 'comparisonPeriod'
  > = [
    'customers',
    'agents',
    'compareDate1',
    'compareDate2',
    'comparisonPeriod',
  ];

  public xAxisData: string[] = [];
  public series: echarts.ECharts[] | any = [];

  public get currencySymbol() {
    return gdbx.currencySymbol;
  }

  public get yAxisLabel2() {
    let label: string = '';

    label = this.currencySymbol;

    return label;
  }

  public get dataViewFormatter() {
    return DataViewFormatter.salesTracker;
  }

  public get includeCreditNote() {
    return settingx.now.includeCreditNote;
  }

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

  public get dataDateRange() {
    return [
      moment(this.selectedDateRange[0]).subtract(1, 'year').valueOf(),
      this.selectedDateRange[1],
    ];
  }

  public get expensiveHook() {
    const {
        selectedDateRange,
        includeDebitNote,
        includeCreditNote,
    } = this;
    return JSON.stringify([
        selectedDateRange,
        includeDebitNote,
        includeCreditNote,
    ]);
  }

  public async expensiveCalc() {
    const thisYearRef = FilteredDatabase.ref('sales')
        .includes('docType', ['IV', this.includeDebitNote ? 'DN' : ''])
        .dateRange(this.selectedDateRange);

    const pastYearRef = FilteredDatabase.ref('sales')
        .includes('docType', ['IV', this.includeDebitNote ? 'DN' : ''])
        .dateRange([
            moment(this.selectedDateRange[0]).subtract(1, 'year').valueOf(),
            moment(this.selectedDateRange[1]).subtract(1, 'year').valueOf(),
        ]);

    const thisYearCNRef = FilteredDatabase.ref('creditNotes')
        .dateRange(this.selectedDateRange);

    const pastYearCNRef = FilteredDatabase.ref('creditNotes')
        .dateRange([
          moment(this.selectedDateRange[0]).subtract(1, 'year').valueOf(),
          moment(this.selectedDateRange[1]).subtract(1, 'year').valueOf(),
        ]);

    let pa = 0.33;
    let pb = 0;

    const thisYearDD = await this._loadDimensionByPeriod(
        thisYearRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.33;

    const pastYearDD = await this._loadDimensionByPeriod(
        pastYearRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.34;

    const thisYearCNDD = await this._loadDimensionByPeriod(
        thisYearCNRef,
        'month',
        pa,
        pb,
        'amount',
    );

    const pastYearCNDD = await this._loadDimensionByPeriod(
        pastYearCNRef,
        'month',
        pa,
        pb,
        'amount',
    );

    this.xAxisData = thisYearDD.map((dd ) => {
      return dd.text;
    });

    this.series = [
      {
        name: 'This Year',
        type: 'bar',
        smooth: true,
        barWidth: '20%',
        color: GobiColor.COLOR1,
        data: thisYearDD.map(
            (dd, index) =>
                dd.sum -
                (this.includeCreditNote ? thisYearCNDD[index].sum : 0),
        ),
      },
      {
        name: 'Last Year',
        type: 'bar',
        smooth: true,
        barWidth: '20%',
        color: GobiColor.COLOR3,
        data: pastYearDD.map(
            (dd, index) =>
                dd.sum -
                (this.includeCreditNote ? pastYearCNDD[index].sum : 0),
        ),
      },
    ];

  }
}
