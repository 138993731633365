
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import settingx from '@/store/modules/settingx';
import Checkbox from 'primevue/checkbox';

@Component({
	components: { Checkbox },
})
export default class IncludeDebitNoteToggle extends Vue {
	public localIncludeDebitNote: boolean = this.globalIncludeDebitNote;

	public get globalIncludeDebitNote() {
		return settingx.now.includeDebitNote;
	}

	@Watch('localIncludeDebitNote')
	public onLocalIncludeDebitNoteChanged(newVal) {
		settingx.updateSettings({ includeDebitNote: newVal });
	}

	@Watch('globalIncludeDebitNote')
	public onGlobalIncludeDebitNoteChanged(newVal) {
		this.localIncludeDebitNote = newVal;
	}
}
