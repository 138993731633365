
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AgentSalesMetricTableFilterable from '@/components/reports-v2/components/filterables/AgentSalesMetricTableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	PermissionsGroup,
	AgentSalesMetrics,
	DimensionMap,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import moment from 'moment';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
	components: {
		AgentSalesMetricTableFilterable,
		DateFilter,
		IncludeTaxToggle,
		IncludeCreditNoteToggle,
	},
})
export default class AgentSalesMetricTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales', 'quotations'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'compareDate1'
		| 'compareDate2'
	> = ['stockItems', 'agents', 'compareDate1', 'compareDate2'];

	public year1Result = {} as AgentSalesMetrics;
	public year2Result = {} as AgentSalesMetrics;

	public selectedDate1 = moment().add(-1, 'year').startOf('year').valueOf();
	public selectedDate2 = moment().startOf('year').valueOf();

	public get includeTax() {
		return settingx.now.includeTax;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get expensiveHook() {
		const {
			includeTax,
			selectedDate1,
			selectedDate2,
			selectedStockItems,
			selectedAgents,
			includeCreditNote,
		} = this;
		return JSON.stringify([
			includeTax,
			selectedDate1,
			selectedDate2,
			selectedStockItems,
			selectedAgents,
			includeCreditNote,
		]);
	}

	public async expensiveCalc() {
		const mmt = moment();
		const currentMonth = mmt.month() + 1;
		const currentYear = mmt.year();

		const salesYear1Ref = FilteredDatabase.ref('sales')
			.year(moment(this.selectedDate1).year())
			.stockItems(this.selectedStockItems)
			.agents(this.selectedAgents);

		const cnYear1Ref = FilteredDatabase.ref('creditNotes')
			.year(moment(this.selectedDate1).year())
			.stockItems(this.selectedStockItems)
			.agents(this.selectedAgents);

		const salesYear2Ref = FilteredDatabase.ref('sales')
			.year(moment(this.selectedDate2).year())
			.stockItems(this.selectedStockItems)
			.agents(this.selectedAgents);

		const cnYear2Ref = FilteredDatabase.ref('creditNotes')
			.year(moment(this.selectedDate2).year())
			.stockItems(this.selectedStockItems)
			.agents(this.selectedAgents);

		const quotationYear1Ref = FilteredDatabase.ref('quotations')
			.year(moment(this.selectedDate1).year())
			.stockItems(this.selectedStockItems)
			.agents(this.selectedAgents);

		const quotationYear2Ref = FilteredDatabase.ref('quotations')
			.year(moment(this.selectedDate2).year())
			.stockItems(this.selectedStockItems)
			.agents(this.selectedAgents);

		let pa = 0.1;
		let pb = 0;

		const salesYear1RefHead = await salesYear1Ref.get();
		const salesYear2RefHead = await salesYear2Ref.get();

		const cnYear1RefHead = await cnYear1Ref.get();
		const cnYear2RefHead = await cnYear2Ref.get();

		const quotationsYear1RefHead = await quotationYear1Ref.get();
		const quotationsYear2RefHead = await quotationYear2Ref.get();

		const year1 = moment(this.selectedDate1).get('year');
		const year2 = moment(this.selectedDate2).get('year');

		// ********
		// Sales
		// ********

		pb += pa;
		pa = 0.1;

		const year1SalesDocQty = (await salesYear1RefHead.getSet('docNo')).size;
		const year2SalesDocQty = (await salesYear2RefHead.getSet('docNo')).size;

		const year1SalesDocDateQty = (await salesYear1RefHead.getSet('date')).size;
		const year2SalesDocDateQty = (await salesYear2RefHead.getSet('date')).size;

		pb += pa;
		pa = 0.1;

		const year1SalesAmount = await salesYear1RefHead.getSum(
			this.includeTax ? 'amountIncludeTax' : 'amount',
		);
		const year2SalesAmount = await salesYear2RefHead.getSum(
			this.includeTax ? 'amountIncludeTax' : 'amount',
		);

		const year1CNAmount = await cnYear1RefHead.getSum(
			this.includeTax ? 'amountIncludeTax' : 'amount',
		);
		const year2CNAmount = await cnYear2RefHead.getSum(
			this.includeTax ? 'amountIncludeTax' : 'amount',
		);

		const year1AverageSalesPerCase =
			year1SalesDocQty !== 0
				? (year1SalesAmount - (this.includeCreditNote ? year1CNAmount : 0)) /
				  year1SalesDocQty
				: 0;
		const year2AverageSalesPerCase =
			year2SalesDocQty !== 0
				? (year2SalesAmount - (this.includeCreditNote ? year2CNAmount : 0)) /
				  year2SalesDocQty
				: 0;

		const year1AverageSalesCase =
			year1 === currentYear
				? year1SalesDocQty / currentMonth
				: year1SalesDocQty / 12;
		const year2AverageSalesCase =
			year2 === currentYear
				? year2SalesDocQty / currentMonth
				: year2SalesDocQty / 12;

		const year1AverageSales =
			year1 === currentYear
				? (year1SalesAmount - (this.includeCreditNote ? year1CNAmount : 0)) /
				  currentMonth
				: year1SalesAmount / 12;
		const year2AverageSales =
			year2 === currentYear
				? (year2SalesAmount - (this.includeCreditNote ? year2CNAmount : 0)) /
				  currentMonth
				: year2SalesAmount / 12;

		const year1AverageSalesCycle =
			year1SalesDocDateQty !== 0
				? moment().endOf('year').year(year1).dayOfYear() / year1SalesDocDateQty
				: 0;
		const year2AverageSalesCycle =
			year2SalesDocDateQty !== 0
				? moment().endOf('year').year(year2).dayOfYear() / year2SalesDocDateQty
				: 0;

		// ********
		// Quotation
		// ********

		pb += pa;
		pa = 0.1;

		const docNoSet1 = await quotationsYear1RefHead.getSet('docNo');
		const docNoSet2 = await quotationsYear2RefHead.getSet('docNo');

		const docNoList1 = [...docNoSet1];
		const docNoList2 = [...docNoSet2];

		const docNoMap1: DimensionMap[] = docNoList1.map((docNo) => ({
			filterType: 'string',
			filterKey: 'docNo',
			value: [docNo],
			text: docNo,
		}));

		const docNoMap2: DimensionMap[] = docNoList2.map((docNo) => ({
			filterType: 'string',
			filterKey: 'docNo',
			value: [docNo],
			text: docNo,
		}));

		const year1QuotationDocQty = docNoList1.length;
		const year2QuotatioDocQty = docNoList2.length;

		pb += pa;
		pa = 0.1;

		const year1QuotationAmount = await quotationsYear1RefHead.getSum(
			this.includeTax ? 'amountIncludeTax' : 'amount',
		);
		const year2QuotationAmount = await quotationsYear2RefHead.getSum(
			this.includeTax ? 'amountIncludeTax' : 'amount',
		);

		this.generateLoadingText(0.9);

		const year1AverageQuotationCase =
			year1 === currentYear
				? year1QuotationDocQty / currentMonth
				: year1QuotationDocQty / 12;
		const year2AverageQuotationCase =
			year2 === currentYear
				? year2QuotatioDocQty / currentMonth
				: year2QuotatioDocQty / 12;

		pb += pa;
		pa = 0.15;

		let docNoDD1 = await this._loadDimension(
			quotationYear1Ref,
			docNoMap1,
			pa,
			pb,
			'convertedQuotation',
		);

		docNoDD1 = docNoDD1.filter((dd) => dd.sum !== 0);

		const year1ConvertedQuotationCaseQty = docNoDD1.length;

		pb += pa;
		pa = 0.15;

		let docNoDD2 = await this._loadDimension(
			quotationYear2Ref,
			docNoMap2,
			pa,
			pb,
			'convertedQuotation',
		);

		docNoDD2 = docNoDD2.filter((dd) => dd.sum !== 0);

		const year2ConvertedQuotationCaseQty = docNoDD2.length;

		const year1WinRate =
			year1QuotationDocQty !== 0
				? (year1ConvertedQuotationCaseQty / year1QuotationDocQty) * 100
				: 0;
		const year2WinRate =
			year2QuotatioDocQty !== 0
				? (year2ConvertedQuotationCaseQty / year2QuotatioDocQty) * 100
				: 0;

		this.year1Result = {
			year: year1,
			salesAmount:
				year1SalesAmount - (this.includeCreditNote ? year1CNAmount : 0),
			salesDocQty: year1SalesDocQty,
			averageSalesPerCase: year1AverageSalesPerCase,
			averageSalesCase: year1AverageSalesCase,
			averageSales: year1AverageSales,
			averageSalesCycle: year1AverageSalesCycle,
			quotationAmount: year1QuotationAmount,
			quotationDocQty: year1QuotationDocQty,
			averageQuotationCase: year1AverageQuotationCase,
			winRate: year1WinRate,
		};

		this.year2Result = {
			year: year2,
			salesAmount:
				year2SalesAmount - (this.includeCreditNote ? year2CNAmount : 0),
			salesDocQty: year2SalesDocQty,
			averageSalesPerCase: year2AverageSalesPerCase,
			averageSalesCase: year2AverageSalesCase,
			averageSales: year2AverageSales,
			averageSalesCycle: year2AverageSalesCycle,
			quotationAmount: year2QuotationAmount,
			quotationDocQty: year2QuotatioDocQty,
			averageQuotationCase: year2AverageQuotationCase,
			winRate: year2WinRate,
		};

		this.saveHistory('year1Result', 'year2Result');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
