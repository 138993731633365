
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import TotalQuotationCaseDisplayer from '@/components/reports-v2/components/codedWidgets/sales/TotalQuotationCaseDisplayer.vue';
import ConvertedQuotationCaseDisplayer from '@/components/reports-v2/components/codedWidgets/sales/ConvertedQuotationCaseDisplayer.vue';
import OpenQuotationCaseDisplayer from '@/components/reports-v2/components/codedWidgets/sales/OpenQuotationCaseDisplayer.vue';
import SalesProfitAndLossLineChart from '@/components/reports-v2/components/codedWidgets/sales/SalesProfitAndLossLineChart.vue';
import SalesOrderTrendLineChart from '@/components/reports-v2/components/codedWidgets/sales/SalesOrderTrendLineChart.vue';
import AgentSalesBarChart from '@/components/reports-v2/components/codedWidgets/sales/AgentSalesBarChart.vue';
import SalesConversionRateBarChart from '@/components/reports-v2/components/codedWidgets/sales/SalesConversionRateBarChart.vue';
import LastSync from '@/components/LastSync.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import gdbx from '@/store/modules/gdbx';
import AgentSalesMetricTable from '@/components/reports-v2/components/codedWidgets/sales/AgentSalesMetricTable.vue';
import SalesPeriodComparisonLineChart from '../../components/codedWidgets/customer/SalesPeriodComparisonLineChart.vue';
import StockItemSalesByCustomerTable from '@/components/reports-v2/components/codedWidgets/stock/StockItemSalesByCustomerTable.vue';
import TotalSalesComparisonByMonthBarChart from '@/components/reports-v2/components/codedWidgets/sales/TotalSalesComparisonByMonthBarChart.vue';
import PercentSalesByRegionBarChart from '@/components/reports-v2/components/codedWidgets/sales/PercentSalesByRegionBarChart.vue';
import TotalSalesComparisonByRegionBarChart from '@/components/reports-v2/components/codedWidgets/sales/TotalSalesComparisonByRegionBarChart.vue';
import TotalSalesByRegionBarChart from '@/components/reports-v2/components/codedWidgets/sales/TotalSalesByRegionBarChart.vue';
import TotalSalesByIndustryBarChart from '@/components/reports-v2/components/codedWidgets/sales/TotalSalesByIndustryBarChart.vue';

@Component({
	components: {
    TotalSalesByIndustryBarChart,
    TotalSalesByRegionBarChart,
    TotalSalesComparisonByRegionBarChart,
    PercentSalesByRegionBarChart,
    TotalSalesComparisonByMonthBarChart,
		StickyFilterContainer,
		TotalQuotationCaseDisplayer,
		ConvertedQuotationCaseDisplayer,
		OpenQuotationCaseDisplayer,
		SalesProfitAndLossLineChart,
		AgentSalesBarChart,
		SalesConversionRateBarChart,
		LastSync,
		DateRangeFilter,
		AgentSalesMetricTable,
		SalesPeriodComparisonLineChart,
		StockItemSalesByCustomerTable,
		SalesOrderTrendLineChart,
	},
})
export default class SalesAnalysis extends Vue {
	public selectedDay: number = moment().endOf('day').valueOf();
	public selectedDateRange: [number, number] = [
		moment().startOf('month').valueOf(),
		moment().endOf('day').valueOf(),
	];

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).valueOf();
	}

	public get selectedAgents() {
		return gdbx.allAgents;
	}

	public get selectedStockItems() {
		return gdbx.allStockItems;
	}

	public get selectedCustomers() {
		return gdbx.allCustomers;
	}



	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
