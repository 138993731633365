
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StackedBarChartFilterable from '../../filterables/StackedBarChartFilterable.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import * as echarts from 'echarts';
import moment from 'moment';
import { GobiColor } from '@/helpers/color';
import { TooltipFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tooltipFormatter';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
	components: {
		StackedBarChartFilterable,
		DateFilter,
		SelectFilter,
		IncludeCreditNoteToggle,
	},
})
export default class SalesPeriodComparisonLineChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales', 'customers'];
	}

	public filterIds: Array<
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'compareDate1'
		| 'compareDate2'
		| 'comparisonPeriod'
	> = [
		'customers',
		'agents',
		'compareDate1',
		'compareDate2',
		'comparisonPeriod',
	];

	public xAxisData: number[] = [];
	public series: echarts.ECharts[] | any = [];
	public selectedDate1 = moment().startOf('month').add(-1, 'month').valueOf();
	public selectedDate2 = moment()
		.startOf('month')
		.add(-1, 'month')
		.add(-1, 'year')
		.valueOf();
	public period: '31 days' | '12 months' | '24 months' = '31 days';
	public periodOptions = ['31 days', '12 months', '24 months'];

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get selectedDateRange1(): [number, number] {
		const periodArgument = this.period.split(' ');
		return [
			moment(this.selectedDate1).startOf('day').valueOf(),
			moment(this.selectedDate1)
				.add(...this.periodArgument)
				.endOf('day')
				.valueOf(),
		];
	}

	public get selectedDateRange2(): [number, number] {
		return [
			moment(this.selectedDate2).startOf('day').valueOf(),
			moment(this.selectedDate2)
				.add(...this.periodArgument)
				.endOf('day')
				.valueOf(),
		];
	}

	public get periodArgument(): [number, 'days' | 'months'] {
		const pa = this.period.split(' ');
		return [parseInt(pa[0], 10), pa[1] as 'days' | 'months'];
	}

	public get xAxisLabel() {
		return this.periodArgument[1] === 'months' ? 'Month' : 'Day';
	}

	public get formatter() {
		const pa = this.periodArgument;
		return pa[1] === 'days'
			? TooltipFormatter.comparisonDay
			: TooltipFormatter.comparisonMonth;
	}

	public get expensiveHook() {
		const {
			periodArgument,
			selectedDateRange1,
			selectedDateRange2,
			selectedAgents,
			selectedCustomers,
			includeCreditNote,
		} = this;
		return JSON.stringify([
			periodArgument,
			selectedDateRange1,
			selectedDateRange2,
			selectedAgents,
			selectedCustomers,
			includeCreditNote,
		]);
	}

	public async expensiveCalc() {
		const salesRef1 = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange1)
			.customers(this.selectedCustomers)
			.includes('docType', ['IV', 'DN']);

		const cnRef1 = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange1)
			.customers(this.selectedCustomers)
			.includes('docType', ['CN']);

		const salesRef2 = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange2)
			.customers(this.selectedCustomers)
			.includes('docType', ['IV', 'DN']);

		const cnRef2 = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange2)
			.customers(this.selectedCustomers)
			.includes('docType', ['CN']);

		let pa = 0.25;
		let pb = 0;

		const salesRef1DD = await this._loadDimensionByPeriod(
			salesRef1,
			this.periodArgument[1] === 'months' ? 'month' : 'day',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.25;

		const cnRef1DD = await this._loadDimensionByPeriod(
			cnRef1,
			this.periodArgument[1] === 'months' ? 'month' : 'day',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.25;

		const salesRef2DD = await this._loadDimensionByPeriod(
			salesRef2,
			this.periodArgument[1] === 'months' ? 'month' : 'day',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.25;

		const cnRef2DD = await this._loadDimensionByPeriod(
			cnRef2,
			this.periodArgument[1] === 'months' ? 'month' : 'day',
			pa,
			pb,
			'amount',
		);

		const n = this.periodArgument[0];
		this.xAxisData = [...Array(n + 1).keys()].slice(1);

		const result = [
			{
				name: moment(this.selectedDate1).format('DD MMM YYYY'),
				type: 'bar',
				smooth: true,
				barWidth: '40%',
				color: GobiColor.COLOR1,
				data: salesRef1DD.map(
					(dd, index) =>
						dd.sum - (this.includeCreditNote ? cnRef1DD[index].sum : 0),
				),
			},
			{
				name: moment(this.selectedDate2).format('DD MMM YYYY'),
				type: 'bar',
				smooth: true,
				barWidth: '40%',
				color: GobiColor.COLOR3,
				data: salesRef2DD.map(
					(dd, index) =>
						dd.sum - (this.includeCreditNote ? cnRef2DD[index].sum : 0),
				),
			},
		];

		this.series = result;

		this.saveHistory('series', 'xAxisData');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
